import { UrlObject } from 'url';
import { NextRouter } from 'next/router';

/**
 * Use this for Next Link
 */
export const urlSearchParamsToUrl = (params: URLSearchParams): UrlObject => {
  if (params.toString().length === 0) {
    return {};
  }
  params.sort();
  return { query: Object.fromEntries(params.entries()) };
};

/**
 * Use this to ensure the url params are interpolated properly
 */
export const urlSearchParamsToUrlWithPathname = (
  router: NextRouter,
  params: URLSearchParams,
): UrlObject => {
  return {
    pathname: router.asPath.split('?')[0],
    ...urlSearchParamsToUrl(params),
  };
};

/**
 * Use this when creating absolute urls
 */
export const urlSearchParamsToHref = (params: URLSearchParams): string => {
  if (params.toString().length === 0) {
    return '';
  }
  params.sort();
  return `?${params.toString()}`;
};
