/* eslint-disable */

import * as Types from '@pepper/types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from '@pepper/client/apollo';
const defaultOptions = {} as const;
export type FormOptionsQueryVariables = Types.Exact<{
  facilityType?: Types.InputMaybe<Types.FacilityType>;
  countryIds?: Types.InputMaybe<Array<Types.Scalars['ObjectId']> | Types.Scalars['ObjectId']>;
  countries?: Types.InputMaybe<Types.Scalars['Boolean']>;
  facilityIds?: Types.InputMaybe<Array<Types.Scalars['ObjectId']> | Types.Scalars['ObjectId']>;
  facilities?: Types.InputMaybe<Types.Scalars['Boolean']>;
  facilityStatuses?: Types.InputMaybe<Array<Types.FacilityStatus> | Types.FacilityStatus>;
  partners?: Types.InputMaybe<Types.Scalars['Boolean']>;
  partnerStatus?: Types.InputMaybe<Array<Types.PartnerStatus> | Types.PartnerStatus>;
  users?: Types.InputMaybe<Types.Scalars['Boolean']>;
  userRoles?: Types.InputMaybe<Array<Types.UserRole> | Types.UserRole>;
}>;


export type FormOptionsQuery = { allCountries?: { nodes: Array<{ _id: string, name: string, timezone: string, countryCode: string, facilityType: Types.FacilityType, value: string, label: string }> }, allFacilities?: { nodes: Array<{ _id: any, name: string, timezone: string, countryId: any, facilityType: Types.FacilityType, value: any, label: string }> }, allPartners?: { nodes: Array<{ _id: any, name: string, facilityType: Types.FacilityType, value: any, label: string }> }, allUsers?: { nodes: Array<{ _id: string, fullName: string, value: string, label: string, access: { label: string } }> } };


export const FormOptionsDocument = gql`
    query formOptions($facilityType: FacilityType, $countryIds: [ObjectId!], $countries: Boolean = false, $facilityIds: [ObjectId!], $facilities: Boolean = false, $facilityStatuses: [FacilityStatus!] = [Active, UnderConstruction], $partners: Boolean = false, $partnerStatus: [PartnerStatus!] = [Active, Draft], $users: Boolean = false, $userRoles: [UserRole!]) {
  allCountries(
    filter: {facilityType: $facilityType}
    sort: {sortBy: Name, sortOrder: ASC}
    first: 1000
  ) @include(if: $countries) {
    nodes {
      _id
      name
      value: _id
      label: name
      timezone
      countryCode
      facilityType
    }
  }
  allFacilities(
    filter: {statuses: $facilityStatuses, facilityType: $facilityType, countryIds: $countryIds}
    sort: {sortBy: "name", sortOrder: ASC}
    first: 1000
  ) @include(if: $facilities) {
    nodes {
      _id
      name
      value: _id
      label: name
      timezone
      countryId
      facilityType
    }
  }
  allPartners(
    filter: {status: $partnerStatus, facilityType: $facilityType, facilityIds: $facilityIds}
    sort: {sortBy: Name, sortOrder: ASC}
    first: 5000
  ) @include(if: $partners) {
    nodes {
      _id
      name
      value: _id
      label: name
      facilityType
    }
  }
  allUsers(
    filter: {userRoles: $userRoles, facilityType: $facilityType, deactivated: false, countryIds: $countryIds}
    sort: {sortBy: Name, sortOrder: ASC}
    first: 1000
  ) @include(if: $users) {
    nodes {
      _id
      fullName
      value: _id
      label: fullName
      access {
        label
      }
    }
  }
}
    `;
export function useFormOptionsQuery(baseOptions?: Apollo.QueryHookOptions<FormOptionsQuery, FormOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormOptionsQuery, FormOptionsQueryVariables>(FormOptionsDocument, options);
      }
export function useFormOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormOptionsQuery, FormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormOptionsQuery, FormOptionsQueryVariables>(FormOptionsDocument, options);
        }
export type FormOptionsQueryHookResult = ReturnType<typeof useFormOptionsQuery>;
export type FormOptionsLazyQueryHookResult = ReturnType<typeof useFormOptionsLazyQuery>;
export type FormOptionsQueryResult = Apollo.QueryResult<FormOptionsQuery, FormOptionsQueryVariables>;
export async function getServerPageFormOptions
    (options: Omit<Apollo.QueryOptions<FormOptionsQueryVariables>, 'query'>, ctx?: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<FormOptionsQuery>({ ...options, query: FormOptionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                __APOLLO_STATE__: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageFormOptionsComp = React.FC<{data?: FormOptionsQuery, error?: Apollo.ApolloError}>;
export const ssrFormOptions = {
      getServerPage: getServerPageFormOptions,
      
      
    }