import { GraphQLSchema } from '@pepper/types';
import { FieldPolicy, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

/**
 * Only the queries which use pagination need to be added to `typePolicies.Query`.
 * This tells apollo what are the input params which impact the result of pagination queries and
 * how to cache individual edge/nodes
 *
 * @see https://apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
 *
 * `keyFields` is like the primary key for an entity, by default it is `id` or `_id`. We can override it to be something else:
 * - Set to an array of keys, the combination of those keys is used as the primary key for caching the entity
 * - Set to false for some entities which are not unique per id,
 *   like `Item` where 2 order items for different orders can have the same item id but different counts
 *   so apollo should not cache `Item` but keep it as part of parent entity (i.e. `Order`)
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
 *
 * `merge` is used to merge the values in the cache rather than overwriting them.
 * This is useful when you might get partials of the same entity, for eg. from a subscription that queries partial updates only
 *
 * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
 */
export const getApolloCache = (): InMemoryCache => {
  const queries: Partial<
    Omit<Record<keyof GraphQLSchema.Query, FieldPolicy<any>>, '__typename'>
  > = {
    allBookmarks: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allBrands: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allCountries: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allFacilities: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allLeads: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allLeases: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allOrders: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allProducts: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allPartners: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allStalls: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allUsers: relayStylePagination(['filter', 'sort', 'first', 'after']),
    allVendors: relayStylePagination(['filter', 'sort', 'first', 'after']),
  };

  function nullable() {
    // Create a generic field policy that allows any field to be null by default:
    return {
      read(existing = null) {
        return existing;
      },
    };
  }

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: queries,
      },
      Access: {
        keyFields: false,
      },
      DefaultColumn: {
        keyFields: false,
      },
      CustomColumns: {
        keyFields: false,
      },
      OrderCustomColumns: {
        keyFields: false,
      },
      OrderManagement: {
        keyFields: false,
        merge: true,
      },
      OnboardingDetails: {
        keyFields: false,
      },
      OffboardingDetails: {
        keyFields: false,
      },
      PartnerAssignee: {
        keyFields: false,
      },
      Product: {
        keyFields: ['productId', 'vendorId', 'globalEntityId'],
      },
      StallSchedule: {
        keyFields: false,
      },
      Item: {
        keyFields: false,
      },
      Option: {
        keyFields: false,
      },
      Vendor: {
        keyFields: ['id', 'globalEntityId'],
      },
      History: {
        fields: {
          manual: nullable(),
        },
      },
    },
  });
  return cache;
};
